.divButton {
  display: flex;
  justify-content: space-between;
}

.wrap-promo-code {
  display: flex;
  background: #30313d;
  width: 100%;
  height: 44px;
  padding: 12px;
  border-radius: 6px;
  margin: 24px 0px;
}

.wrap-promo-code-no-input {
  margin: 24px 0px;
  color: #0074cc;
  text-decoration: inherit;
  border: 1px solid #ffffff;
  height: 44px;
  width: 25%;
  padding: 12px;
  background: #ffffff;
  border-radius: 6px;
}

.wrap-promo-code input {
  width: 80%;
  color: #ffffff;
  outline: none !important;
  background: #30313d;
}
.wrap-promo-code input :focus-visible {
  border: none !important;
}

.wrap-promo-code label {
  color: #ffffff;
  padding-left: 24px;
  cursor: pointer;
  display: flex;
}
.wrap-promo-code label:hover {
  color: #0074d4;
}

.text-error {
  color: #ea5a54;
  margin-bottom: 24px;
}

@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .wrap-promo-code {
    width: 100%;
  }
}
